@import "./theme-settings.scss";

.add-new-styles-here {
  color: blue;
}
.mini-header-text {
  margin-top: 10px;
  @media screen and (min-width: 768px) {
    margin-top: 20px;
  }
}
.ant-input-group-compact .ant-input {
  font-size: 14px !important;
}
.main-navbar .logo span {
  display: none;
}
