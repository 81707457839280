@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

$headersFont: "Roboto";
$textFont: "Roboto";

$primary-color: #e87b22;
$header-bg-color: black;
$blue-color: #0d77ce;
$light-blue-color: #0d77ce;
$green-color: #09b437;
$light-green-color: #09b437;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: #666;
$placeholder-color: #747474;
$wrapper-bg-color: #eceff1;

$navbarLogoWidthSm: 230px;
$navbarLogoWidthMd: 400px;
$navbarLogoWidthLg: 420px;

$miniNavbarLogoWidthSm: 255px;
$miniNavbarLogoWidthMd: 420px;
$miniNavbarLogoWidthLg: 430px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 80px;
$wrapperFWMinheightSm: 80px;
$wrapperFWMinheightMd: 135px;
$wrapperFWMinheightLg: 135px;
$wrapperFWMinheightXl: 174px;
// static page
$wrapperMinheightXs: 80px;
$wrapperMinheightSm: 80px;
$wrapperMinheightMd: 107px;
$wrapperMinheightLg: 107px;
$wrapperMinheightXl: 158px;

$slideBg: black;
